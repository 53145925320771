<!-- eslint-disable max-len -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M8.82591 11.1749C9.16758 11.4999 9.16758 12.0333 8.82591 12.3583C8.50091 12.6833 7.96758 12.6833 7.64258 12.3583C6.01758 10.7333 6.01758 8.0916 7.64258 6.4666L10.5926 3.5166C12.2176 1.8916 14.8592 1.8916 16.4842 3.5166C18.1092 5.1416 18.1092 7.78327 16.4842 9.40827L15.2426 10.6499C15.2509 9.9666 15.1426 9.28327 14.9092 8.63327L15.3009 8.23327C16.2842 7.25827 16.2842 5.67493 15.3009 4.69993C14.3259 3.7166 12.7426 3.7166 11.7676 4.69993L8.82591 7.6416C7.84258 8.6166 7.84258 10.1999 8.82591 11.1749ZM11.1759 7.6416C11.5009 7.3166 12.0342 7.3166 12.3592 7.6416C13.9842 9.2666 13.9842 11.9083 12.3592 13.5333L9.40925 16.4833C7.78425 18.1083 5.14258 18.1083 3.51758 16.4833C1.89258 14.8583 1.89258 12.2166 3.51758 10.5916L4.75924 9.34994C4.75091 10.0333 4.85925 10.7166 5.09258 11.3749L4.70091 11.7666C3.71758 12.7416 3.71758 14.3249 4.70091 15.2999C5.67591 16.2833 7.25924 16.2833 8.23424 15.2999L11.1759 12.3583C12.1592 11.3833 12.1592 9.79993 11.1759 8.82493C10.8342 8.49994 10.8342 7.9666 11.1759 7.6416Z"
      fill="currentColor"
    />
  </svg>
</template>
