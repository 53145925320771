import store from '@/store';
import { useToast } from 'vue-toastification';
import Api from '../helpers/api';

const toast = useToast();
const idToken = store.getters['authenticate/idToken'];

const createEntityRelationships = async (relationships: {
  childIde: string,
  parentIde: string,
  parentFin: string,
  childFin: string,
}[]) => {
  let response = null;
  const body = {
    relationships,
  };
  try {
    response = await (new Api(process.env, idToken)).post('interdocumentrelationship/create', body);
  } catch (err) {
    toast.error('Failed to create relationship');
    if (err instanceof Error) {
      throw new Error(err.message);
    }
  }
  return response;
};

export default createEntityRelationships;
