<template>
  <div
    v-click-outside-element="onClickOutsideElement"
    class="drop-down-enabled"
    :class="customClasses"
    data-cy="dropdown"
  >
    <div
      class="dropdown"
      :class="{'dropdown-active': isDropdownActive}"
    >
      <div
        class="dropdown"
        data-testid="dropdown-trigger"
        @click="dropdownClicked"
      >
        <slot name="trigger" />
      </div>
      <div
        class="dropdown-content"
        :style="customDropdownStyle"
        @click="onClose"
      >
        <slot
          name="content"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isEnabled: {
      type: Boolean,
      default: true,
    },
    customClasses: {
      type: [Array, Object],
      default: () => [],
    },
    customDropdownStyle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDropdownActive: false,
    };
  },
  watch: {
    isEnabled(newIsEnabled) {
      if (!newIsEnabled) {
        this.onClose();
      }
    },
  },
  methods: {
    dropdownClicked() {
      this.isDropdownActive = !this.isDropdownActive;
      this.$log.info('dropdown click');
    },
    onClose() {
      this.isDropdownActive = false;
    },
    onClickOutsideElement() {
      this.onClose();
    },
  },
};
</script>
